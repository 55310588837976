import * as React from 'react';
import { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { fetchDataloggers, selectDataloggers } from '../features/dataloggerList/dataloggersSlice';
import { useTranslation } from 'react-i18next';

import DataloggerList from '../features/dataloggerList/DataloggerList';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Subheader from '../components/layout/Subheader';
import Panel from '../components/layout/Panel';
import DataloggerMap from '../features/dataloggerMap/DataloggerMap';

const DataloggerListPage: React.FC = () => {
  const isLoading = useSelector((state: any) => state.dataloggers.isLoading);
  const dataloggers = useSelector(selectDataloggers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDataloggers());
  }, [dispatch]);
  const { t } = useTranslation();

  return (
    <div>
      <Breadcrumbs />
      <Subheader>{t('Monitoring stations')}</Subheader>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <Row>
          <Col xl="6">
            <Panel>
              {(dataloggers.length > 0) && (
                <DataloggerMap dataloggers={dataloggers} />
              )}
            </Panel>
          </Col>
          <Col xl="6">
            <Panel>
              <DataloggerList dataloggers={dataloggers} />
            </Panel>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default DataloggerListPage;
