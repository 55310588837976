import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Subheader from '../components/layout/Subheader';
import { PartialDashboard } from '../api';
import Panel from 'components/layout/Panel';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useState } from 'react';

interface DashboardListPageProps {
  dashboards?: PartialDashboard[];
}

const DashboardListPage: React.FC<DashboardListPageProps> = (props) => {
  const {
    dashboards,
  } = props;

  return (
    <div className="DashboardListPage">
      <Breadcrumbs />
      <Subheader>Dashboards</Subheader>
      {!dashboards ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Panel>
            <DashboardList dashboards={dashboards} />
          </Panel>
        </div>
      )}
    </div>
  );
};

const formatDashboardLink = (selector: string) => (row: any) => (
  <Link to={`/dashboards/${row.id}/`}>{row[selector]}</Link>
);

const getColumns = (t: (s: string) => string) => [
  {
    name: t('Name'),
    selector: 'title',
    sortable: true,
    wrap: true,
    grow: 1,
    format: formatDashboardLink('title'),
  },
  {
    name: t('Client'),
    selector: 'client',
    sortable: true,
    wrap: true,
    grow: 1,
  },
];
interface DashboardListProps {
  dashboards: PartialDashboard[];
}
const DashboardList = (props: DashboardListProps) => {
  const { dashboards } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  let filteredDashboards = dashboards;
  if(search) {
    filteredDashboards = filteredDashboards.filter(dashboard => (
      dashboard.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      dashboard.client.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ));
  }

  return (
    <div>
      <input
        type="text"
        className="form-control mb-4"
        placeholder={t('Search')}
        value={search}
        onChange={e => {setSearch(e.target.value)}}
      />
      <DataTable
        noHeader
        pagination={false}
        defaultSortField="title"
        className="table table-bordered table-hover w-100"
        columns={getColumns(t)}
        data={filteredDashboards}
      />
    </div>
  );
}

export default DashboardListPage;
