import * as React from 'react';
import { API_BASE_URL, GaugeLineWidget, ImageWidget, MediaWidget, TerseExportResponse, Widget, DashboardDataResponse } from 'api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Gauge from '../../components/charts/Gauge';
import LineChart from '../../components/charts/LineChart';
import InclinometerChart from '../../components/charts/InclinometerChart'
import TemperatureChart from '../../components/charts/TemperatureChart'
import InclinometerXYChart from '../../components/charts/InclinometerXYChart'
import WindRose from '../../components/charts/WindRose';
import ColumnChart from '../../components/charts/ColumnChart';
import Image from 'react-bootstrap/Image';
import Rerenderer from '../../components/utils/Rerenderer';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Moment from 'react-moment';

export interface IDepth {
  title: string;
  key: number;
  axisTitleKey: number;
}

export interface WidgetDisplayProps {
  widget: Widget;
  data: DashboardDataResponse;
  number_of_decimals?: number;
  timeZone?: string;
}
const WidgetDisplay = (props: WidgetDisplayProps) => {
  const {
    widget,
    data,
    number_of_decimals,
    timeZone,
  } = props;
  const largestDifferenceAfterInstallation = data.meta.largestDifferenceAfterInstallation ? data.meta.largestDifferenceAfterInstallation : { x: 0, y: 0 };
  const firstValueDate = data.meta.firstValueDate ? data.meta.firstValueDate : { x: 0, y: 0 };
  const [selectedDepthIndex, setSelectedDepthIndex] = React.useState(0);

  let depths: IDepth[] = [];
  if (['gauge-line', 'inclinometer-xy-chart'].includes(widget.type)) {
    if (data.widgets && data.widgets['w-1']) {
      if (widget.data.extraProps?.gauge?.widget_type === 'temperature-line') {
        data.widgets['w-1'][0].forEach((e: any, index: number) => {
          const depth = 170 - (index + 2) * 10;
          if (e.category) depths.push({ title: e.category + ' cm', key: index, axisTitleKey: depth });
        })
      } else {
        data.widgets['w-1'].forEach((e: any, index: number) => {
          if (e.category) depths.push({ title: e.category + ' m', key: index, axisTitleKey: index });
        })
      }
      depths.reverse();
      depths = depths.map((d, i) => ({ ...d, key: i }))
    }
  }
  const selectedDepth = depths[selectedDepthIndex];

  return (
    <Rerenderer>
      {widget.type === 'gauge-line' ? (
        <GaugeLineWidgetDisplay widget={widget} data={data} number_of_decimals={number_of_decimals} timeZone={timeZone} depths={depths} selectedDepth={selectedDepth} setSelectedDepth={setSelectedDepthIndex} />
      ) : (widget.type === 'inclinometer-line') ? (
        <InclinometerWidgetDisplay widget={widget} data={data.widgets[widget.id]}
          largestDifferenceAfterInstallation={largestDifferenceAfterInstallation}
          firstValueDate={firstValueDate}
        />
      ) : (widget.type === 'temperature-line') ? (
        <TemperatureWidgetDisplay widget={widget} data={data.widgets[widget.id] ? data.widgets[widget.id][0] : []} airTempData={data.widgets[widget.id] ? data.widgets[widget.id][1] : {}}
          firstValueDate={firstValueDate}
        />
      ) : (widget.type === 'image') ? (
        <ImageWidgetDisplay widget={widget} />
      ) : (widget.type === 'media') ? (
        <MediaWidgetDisplay widget={widget} />
      ) : (widget.type === 'inclinometer-xy-chart') ? (
        <InclinometerXyWidgetDisplay widget={widget} data={data} depths={depths} selectedDepth={selectedDepth} setSelectedDepth={setSelectedDepthIndex} />
      ) : (widget.type === 'wind-rose') ? (
        <WindRoseWidgetDisplay widget={widget} data={data} />
      ) : (widget.type === 'column-chart') ? (
        <ColumnChartWidgetDisplay widget={widget} data={data} />
      ) : (<pre>{JSON.stringify(widget, null, 2)}</pre>)}
    </Rerenderer>
  )
}
export default WidgetDisplay;

export interface ColumnChartWidgetDisplayProps {
  widget: Widget;
  data: any;
}

export const ColumnChartWidgetDisplay = ({ widget, data}: ColumnChartWidgetDisplayProps) => {
  const columnChart = <ColumnChart data={data} chartId={widget.id} widget={widget} />;

  return (
    <Container fluid>
      <Col>
        <Row>
          {columnChart}
        </Row>
      </Col>
    </Container>
  )
}

export interface WindRoseWidgetDisplayProps {
  widget: Widget;
  data: any;
}

export const WindRoseWidgetDisplay = ({ widget, data}: WindRoseWidgetDisplayProps) => {
  const windRose = <WindRose data={data} chartId={widget.id} />;

  return (
    <Container fluid>
      <Col>
        <Row>
          {windRose}
        </Row>
      </Col>
    </Container>
  )
}

export interface InclinometerXyWidgetDisplayProps {
  widget: Widget;
  data: TerseExportResponse;
  selectedDepth: IDepth;
  setSelectedDepth: React.Dispatch<number>;
  depths: IDepth[];
}
export const InclinometerXyWidgetDisplay = ({ widget, data, selectedDepth, setSelectedDepth, depths }: InclinometerXyWidgetDisplayProps) => {
  const [showAverageValues, setShowAverageValues] = React.useState(true);
  //const { sources } = widget.data;
  const map_image = widget.data['map_image'];

  let selectDepthButton = null;
  let toggleAverageValuesButton = null;
  if (selectedDepth) {
    selectDepthButton =
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedDepth.title}
          className="float-right mr-2"
          size="sm"
        >
          {depths.map((depth) =>
            <Dropdown.Item
              onClick={() => setSelectedDepth(depth.key)}
              key={depth.title}
            >
              {depth.title}
            </Dropdown.Item>
          )}
        </DropdownButton>
        <Form.Label column sm="1" className="float-right mr-2">
          Syvyys:
        </Form.Label>
      </>

    toggleAverageValuesButton =
      <>
        <Form.Label column sm="2">
          Keskiarvot:
        </Form.Label>
        <Form.Check
          className="float-right mt-2"
          id={`average-switch-${widget.id}`}
          type="switch"
          inline
          label=""
          onChange={() => setShowAverageValues(!showAverageValues)}
          checked={showAverageValues}
        />
      </>
  }

  let dataToChart = null;
  if (showAverageValues) {
    dataToChart = getInclinometerChartData(data.widgets[widget.id]?.avg_datasets, selectedDepth);
  } else {
    dataToChart = getInclinometerChartData(data.widgets[widget.id]?.datasets, selectedDepth);
  }

  const inclinometerXYChart = <InclinometerXYChart
    chartData={dataToChart}
    imageFilename={map_image}
    chartId={widget.id}
  />;

  if (dataToChart) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="text-center pb-5">{widget.title} ({selectedDepth ? selectedDepth.title : null})</h2>
            {inclinometerXYChart}
            <div className="text-right">
              {selectDepthButton}
              {toggleAverageValuesButton}
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return null;
  }
}

export interface InclinometerWidgetDisplayProps {
  widget: Widget;
  data: any;
  largestDifferenceAfterInstallation: { x: number, y: number };
  firstValueDate: { x: number, y: number };
}
export const InclinometerWidgetDisplay = (props: InclinometerWidgetDisplayProps) => {
  const extraProps = props.widget.data?.extraProps || {};
  const horizontalInclinometer = extraProps.horizontalInclinometer || false;
  const firstTimestamp = (props.data && props.data[0]) ? props.data[0].timestamp1 : null;
  const inclinometerChart =
    <InclinometerChart
      chartData={props.data}
      chartId={props.widget.id}
      horizontalInclinometer={horizontalInclinometer}
    />;

  let largestDifference = 0;
  let largestDifferenceAfterInstallation = 0;
  if (props.data?.length > 0) {
    largestDifference = props.data[props.data.length - 1].largest_difference;
    if (props.widget.title === "Y Suunta") {
      largestDifferenceAfterInstallation = props.largestDifferenceAfterInstallation.y;
    } else {
      largestDifferenceAfterInstallation = props.largestDifferenceAfterInstallation.x
    }
  }

  const gaugeProps = extraProps.gauge || {};
  const referenceDate = extraProps.reference_date || "asennukseen";

  const gaugeShiftAfterInstallation =
    <Gauge
      title={`Profiilin suurin muutos verrattuna ${referenceDate}`}
      symbol="mm"
      data={[["", largestDifferenceAfterInstallation]]}
      loading={!props.data}
      number_of_decimals={2}
      middle={0}
      green={10}
      yellow={20}
      red={30}
      needle_in_middle={false}
      {...gaugeProps}
    />

  const gaugeShiftInSelectedTimescale =
    <Gauge
      title="Profiilin suurin muutos valitulla ajanjaksolla"
      symbol="mm"
      data={[["", largestDifference]]}
      loading={!props.data}
      number_of_decimals={2}
      middle={0}
      green={10}
      yellow={20}
      red={30}
      needle_in_middle={false}
      {...gaugeProps}
    />

  return (
    <Container fluid>
      {props.widget.title && (
        <div className="text-center">
          <h2 className="text-center">{props.widget.title}</h2>
          <p>Ensimmäinen vertailupiste: {firstTimestamp ? <Moment format="DD.MM.YYYY HH:mm:ss">{firstTimestamp}</Moment> : ""}</p>
        </div>
      )}
      <Row>
        <Col>
          {inclinometerChart}
        </Col>
      </Row>
      <Row>
        <Col>
          {gaugeShiftAfterInstallation}
        </Col>
        <Col>
          {gaugeShiftInSelectedTimescale}
        </Col>
      </Row>
    </Container>
  )
}

export interface TemperatureWidgetDisplayProps {
  widget: Widget;
  data: any;
  firstValueDate: { x: number, y: number };
  airTempData: any;
}
export const TemperatureWidgetDisplay = (props: TemperatureWidgetDisplayProps) => {
  const airTemperature = props.airTempData.value;

  const temperatureChart =
    <TemperatureChart
      chartData={props.data}
      chartId={props.widget.id}
    />;

  return (
    <Container fluid>
      {props.widget.title && (
        <div className="text-center">
          <h2 className="text-center">{props.widget.title.substring(0, 2)} (<span style={{ color: 'red' }}>{props.widget.title.substring(4, 5)}</span>)</h2>
        </div>
      )}
      <div className="text-center">
        Ilma: {airTemperature} °C
        </div>
      <Row>
        <Col>
          {temperatureChart}
        </Col>
      </Row>
    </Container>
  )
}

export interface ImageWidgetDisplayProps {
  widget: ImageWidget;
}
export const ImageWidgetDisplay = ({ widget }: ImageWidgetDisplayProps) => {
  return (
    <Image
      fluid
      src={widget.data.src}
    />
  )
}

export interface MediaWidgetDisplayProps {
  widget: MediaWidget;
}
export const MediaWidgetDisplay = ({ widget }: MediaWidgetDisplayProps) => {
  const {
    media_type,
    client,
    filename,
    width,
    height,
  } = widget.data;
  if (media_type === 'image') {
    return (
      <Image
        fluid
        width={width}
        height={height}
        src={`${API_BASE_URL}/api/ui/v1/media/${client}/${filename}`}
      />
    )
  } else {
    return (
      <pre>{JSON.stringify(widget, null, 2)}</pre>
    )
  }
}

export interface GaugeLineWidgetDisplayProps {
  widget: GaugeLineWidget;
  data: TerseExportResponse;
  number_of_decimals?: number;
  timeZone?: string;
  selectedDepth: IDepth;
  setSelectedDepth: React.Dispatch<number>;
  depths: IDepth[];
}
export const GaugeLineWidgetDisplay = ({ widget, data, number_of_decimals, timeZone, selectedDepth, setSelectedDepth, depths }: GaugeLineWidgetDisplayProps) => {
  const { t } = useTranslation();
  const [redrawChart, setRedrawChart] = React.useState(true);
  const [visibleGaugeSourceId, setVisibleGaugeSourceId] = React.useState(
    widget?.data?.extraProps?.gauge.one_gauge_for_multiple_values
      ?
      widget.data.sources[0].id : 0
  )

  const {
    sources,
    extraProps = {},
  } = widget.data;
  const {
    min,
    max,
    gauge = {},
  } = extraProps;

  const {
    alarm_limit_visible = true,
  } = gauge;
  const green_start_value = gauge.green_start_value ? gauge.green_start_value : 0;

  const [showAlarmLimits, setShowAlarmLimits] = React.useState(alarm_limit_visible);
  const [showAverageValues, setShowAverageValues] = React.useState(true);
  const [showAirTemperature, setShowAirTemperature] = React.useState(false);

  const meter =
    sources.map(source => {
      if (!source.extra_data?.hide_gauge || source.id === visibleGaugeSourceId) {
        const d = source.type === 'unit' ? data.units : data.formulas;
        const sourceData = d[source.id];

        if (!visibleGaugeSourceId) {
          return (
            <Col key={`gauge-${source.id}`}>
              <Gauge
                title={source.title}
                symbol={source.symbol}
                data={sourceData || []}
                loading={!sourceData}
                number_of_decimals={number_of_decimals}
                {...gauge}
                green_start_value={green_start_value}
              />
            </Col>
          );
        } else {
          return (
            <Col key={`gauge-${source.id}`}>
              <Gauge
                title={source.title}
                symbol={source.symbol}
                data={sourceData || []}
                loading={!sourceData}
                number_of_decimals={number_of_decimals}
                {...gauge}
              />
              {widget.data.sources.map(source =>
                source.extra_data.hide_gauge_button ?
                null
                :
                <Button
                  key={`selectGaugeSourceIdButton-${source.id}`}
                  className={`btn-xs mr-2 mt-5 ${visibleGaugeSourceId === source.id ? "active" : ""}`}
                  onClick={() => setVisibleGaugeSourceId(source.id)}
                >
                  {source.title}
                </Button>
              )}
            </Col>
          );
        }
      } else {
        return null;
      }
    })
  const lineChartDatasets = sources.map(source => {
    const d = source.type === 'unit' ? data.units : data.formulas;
    const sourceData = d[source.id] || [];

    return {
      label: source.title || source.id.toString(),
      data: sourceData,
      symbol: source.symbol,
      type: String(source.type),
      hide_axis_label: source.extra_data.hide_axis_label ? source.extra_data.hide_axis_label : false,
      hidden_by_default: source.extra_data.hidden_by_default ? source.extra_data.hidden_by_default : false,
      axis_id: source.extra_data.axis_id ? source.extra_data.axis_id : null,
    }
  });

  // Add alarm limit values to dataset
  if (gauge.alarm_limit && showAlarmLimits) {
    if (lineChartDatasets[0].data.length > 0) {

      let alarmLimitStartTime = "", alarmLimitEndTime = "";
      lineChartDatasets.forEach((dataset) => {
        if (!alarmLimitStartTime || dataset.data[0][0] < alarmLimitStartTime) {
          alarmLimitStartTime = dataset.data[0][0];
        }
        if (!alarmLimitEndTime || dataset.data[dataset.data.length - 1][0] > alarmLimitEndTime) {
          alarmLimitEndTime = dataset.data[dataset.data.length - 1][0];
        }
      })

      gauge.alarm_limit.forEach((limit: any) => {
        lineChartDatasets.push({
          label: t('Alarm limit') + " " + limit[0],
          data: [[alarmLimitStartTime, limit[1]], [alarmLimitEndTime, limit[1]]],
          symbol: lineChartDatasets[0].symbol,
          type: "Alarm limit",
          hide_axis_label: false,
          hidden_by_default: false,
          axis_id: null,
        });
      })
    }
  }

  // Dataset filtering for the inclinometer and temperarure dashboard line chart
  let filteredLineChartDatasets = lineChartDatasets;
  if (gauge.widget_type === 'inclinometer-line' && selectedDepth) {
    if (showAverageValues) {
      filteredLineChartDatasets = getInclinometerChartData(data.widgets[widget.id]?.avg_datasets, selectedDepth);
    } else {
      filteredLineChartDatasets = getInclinometerChartData(data.widgets[widget.id]?.datasets, selectedDepth);
    }
  } else if (gauge.widget_type === 'temperature-line' && selectedDepth) {
    filteredLineChartDatasets = getInclinometerChartData(lineChartDatasets, selectedDepth, true, showAirTemperature);
  }

  let incOrTempLine = false;
  if (['inclinometer-line', 'temperature-line'].includes(gauge.widget_type)) {
    incOrTempLine = true;
  }

  const lineChart =
    <LineChart
      min={min}
      max={max}
      datasets={filteredLineChartDatasets}
      number_of_decimals={number_of_decimals}
      individual_axes={gauge.individual_axes}
      redrawChart={redrawChart}
      setRedrawChart={setRedrawChart}
      timeZone={timeZone}
      axisTitle={gauge.axis_title}
      stepSize={gauge.step_size}
    />
    ;

  const toggleAlarmLimitsButton =
    <Form.Check
      className="float-right"
      id={`switch-${widget.id}`}
      type="switch"
      label={t('Alarm limits')}
      onChange={() => setShowAlarmLimits(!showAlarmLimits)}
      checked={showAlarmLimits}
    />

  const resetZoomButton =
    <Button
      className={`${redrawChart ? "invisible" : "visible"} btn-sm float-left`}
      onClick={() => setRedrawChart(true)}
    >
      Reset zoom
    </Button>

  let selectDepthButton = null;
  let toggleAverageValuesButton = null;
  if (incOrTempLine && selectedDepth) {
    selectDepthButton =
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedDepth.title}
          className="float-right mr-2"
          size="sm"
        >
          {depths.map((depth) =>
            <Dropdown.Item
              onClick={() => setSelectedDepth(depth.key)}
              key={depth.title}
            >
              {depth.title}
            </Dropdown.Item>
          )}
        </DropdownButton>
        <Form.Label column sm="1" className="float-right mr-2">
          Syvyys:
        </Form.Label>
      </>

    if (gauge.widget_type === 'inclinometer-line') {
      toggleAverageValuesButton =
        <>
          <Form.Label column sm="2">
            Keskiarvot:
          </Form.Label>
          <Form.Check
            className="float-right mt-2"
            id={`average-switch-${widget.id}`}
            type="switch"
            inline
            label=""
            onChange={() => setShowAverageValues(!showAverageValues)}
            checked={showAverageValues}
          />
        </>
    } else {
      toggleAverageValuesButton =
        <>
          <Form.Label column sm="2">
            Ilma:
          </Form.Label>
          <Form.Check
            className="float-right mt-2"
            id={`average-switch-${widget.id}`}
            type="switch"
            inline
            label=""
            onChange={() => setShowAirTemperature(!showAirTemperature)}
            checked={showAirTemperature}
          />
        </>
    }
  }

  return (
    <div className={incOrTempLine ? "container" : "container-fluid"}>
      {widget.title && (
        <h2 className="text-center">
          {widget.title}
          {incOrTempLine && selectedDepth ?
            ` (${selectedDepth.title})`
            :
            null
          }
        </h2>
      )}
      {gauge.type_side_by_side ?
        <Row>
          <>
            {meter}
          </>
          <Col md={8} key={`line-${widget.id}`}>
            {filteredLineChartDatasets ? lineChart : null}
            {gauge.alarm_limit ? toggleAlarmLimitsButton : null}
            {resetZoomButton}
          </Col>
        </Row>
        :
        <>
          <Row>
            {meter}
          </Row>
          <Row className="my-4">
            <Col>
              {lineChart}
              {gauge.alarm_limit ? toggleAlarmLimitsButton : null}
              {resetZoomButton}
              <div className="text-right">
                {incOrTempLine ? selectDepthButton : null}
                {incOrTempLine ? toggleAverageValuesButton : null}
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  )
}


const getInclinometerChartData = (data: any, selectedDepth: any, temperatureValues?: any, showAirTemperature?: Boolean) => {
  let dataToChart = null;
  // Dataset filtering for the inclinometer dashboard line chart
  if (selectedDepth && data) {
    if (temperatureValues) {
      if (showAirTemperature) {
        dataToChart = data.filter(
          (ds: any) => [`Temp1-${selectedDepth.axisTitleKey}`, `Temp2-${selectedDepth.axisTitleKey}`, `Temp3-${selectedDepth.axisTitleKey}`, `Temp4-${selectedDepth.axisTitleKey}`, 'Air temperature'].includes(ds.label)
        );
      } else {
        dataToChart = data.filter(
          (ds: any) => [`Temp1-${selectedDepth.axisTitleKey}`, `Temp2-${selectedDepth.axisTitleKey}`, `Temp3-${selectedDepth.axisTitleKey}`, `Temp4-${selectedDepth.axisTitleKey}`].includes(ds.label)
        );
      }
    } else {
      dataToChart = data.filter(
        (ds: any) => [`X-${selectedDepth.axisTitleKey + 1}`, `Y-${selectedDepth.axisTitleKey + 1}`].includes(ds.label)
      );
    }

    dataToChart = dataToChart.map((ds: any) => {
      return {
        label: ds.label,
        data: ds.data,
        symbol: ds.symbol,
        type: String(ds.type)
      }
    })
    if (dataToChart.length > 0) {
      if (!temperatureValues) {
        dataToChart[0].label = "X Suunta";
        if (dataToChart.lenght > 1) dataToChart[1].label = "Y Suunta";
      } else {
        dataToChart[0].label = "T1";
        dataToChart[1].label = "T2";
        dataToChart[2].label = "T3";
        dataToChart[3].label = "T4";
        if (showAirTemperature) dataToChart[4].label = "Ilma";
      }
    }
  }

  return dataToChart;
}
