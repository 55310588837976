import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const selectedLanguage = localStorage.getItem('selectedLanguage');
const languages = ['en', 'fi'];
const defaultLanguage = 'en';
let browserLanguage = defaultLanguage;
try {
  browserLanguage = navigator.language.slice(0, 2).toLowerCase();
} catch (e) {
  console.error('Error getting browser language:', e);
}
if (languages.indexOf(browserLanguage) === -1) {
  console.log(`Unsupported browser language ${browserLanguage}, falling back to ${defaultLanguage}`)
  browserLanguage = defaultLanguage;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,

    lng: selectedLanguage ? selectedLanguage : browserLanguage,
    fallbackLng: defaultLanguage,
    whitelist: languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },

    keySeparator: false,
    nsSeparator: false
  });

export default i18n;
