import * as React from 'react';
import { NavLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const routeConfig = [
    {
      path: "/dataloggers",
      breadcrumb: t('Monitoring stations'),
    },
    {
      path: "/dashboards",
      breadcrumb: t('Dashboards'),
    }
  ]
  const breadcrumbs = useReactRouterBreadcrumbs(routeConfig);

  return (
    <ol className="breadcrumb page-breadcrumb">
      <li className="breadcrumb-item">
        <NavLink to="/">{t('Mitta Cloud')}</NavLink>
      </li>

      {breadcrumbs.filter(value => value.match.url !== '/').map((value, index) => (
        <li
          key={value.match.url}
          className={classNames(
            'breadcrumb-item',
            {
              'active': index === breadcrumbs.length - 1,
            }
          )}
        >
          <NavLink to={value.match.url}>
            {value.breadcrumb}
          </NavLink>
        </li>
      ))}
      <li className="position-absolute pos-top pos-right d-none d-sm-block"><span className="js-get-date" /></li>
    </ol>
  );
};
export default Breadcrumbs;
