import Overlay from "ol/Overlay";
import * as proj from "ol/proj";
import Feature from "ol/Feature";
import * as geom from "ol/geom";
import LayerVector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import OlMap from "ol/Map.js";
import { TFunction } from 'i18next';
import { Datalogger } from '../../api';
import MapPinIcon from '../../img/ehp-map-pin.png';
import MapAlertIcon from '../../img/alert-map-pin.png';

export const createMarkers = (dataloggers: Datalogger[], map: OlMap, t: TFunction) => {
  const labels: Overlay[] = [];
  let lonLat;

  dataloggers.forEach((logger) => {
    if (logger.lon && logger.lat) {
      lonLat = [logger.lon, logger.lat];
    } else {
      lonLat = [0, 0]
    }

    // Create marker
    const iconFeature = new Feature({
      geometry: new geom.Point(proj.fromLonLat(lonLat)),
      name: logger.name,
      id: logger.id,
    });

    if (lonLat[0] !== 0 && lonLat[1] !== 0) {
      map.addLayer(new LayerVector({
        source: new SourceVector({
          features: [iconFeature]
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.48, 34],
            crossOrigin: 'anonymous',
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.PIXELS,
            src: logger.has_triggered_alerts ? MapAlertIcon : MapPinIcon
          })
        })
      }));
    }

    // Create label
    const container = document.createElement("div");
    container.classList.add("map-label-div");

    const titleDiv = document.createElement("div");
    const titleText = document.createTextNode(logger.name);
    titleDiv.appendChild(titleText);

    const infoA = document.createElement("a");
    const linkText = document.createTextNode(t("Info"));
    infoA.appendChild(linkText);
    infoA.href = `${process.env.PUBLIC_URL}/dataloggers/${logger.idcode}`;

    container.appendChild(titleDiv);
    if (logger.has_triggered_alerts) {
      const alertDiv = document.createElement('div');
      alertDiv.style.fontSize = '0.9em';
      alertDiv.appendChild(
        document.createTextNode(t('Has currently active alerts'))
      );
      container.appendChild(alertDiv);
    }
    titleDiv.appendChild(titleText);

    container.appendChild(infoA);

    const label = new Overlay({
      position: undefined, // setting to undefined position means hiding it
      element: container,
      stopEvent: false,
    });
    labels.push(label);
    map.addOverlay(label);

    // ugly hack to associate feature with label
    (iconFeature as any).label = label;
  });

  // Map interaction
  map.on("singleclick", function (e) {
    e.preventDefault()
    for (let label of labels) {
      label.setPosition(undefined);
    }

    let featureCount = 0;
    map.forEachFeatureAtPixel(
      e.pixel,
      function (feature: any) {
        if (feature && featureCount === 0) {
          feature.label.setPosition(e.coordinate);
          featureCount++;
        }
      }
    )
  });

  map.on('pointermove', function (e) {
    e.preventDefault();
    map.getTargetElement().style.cursor =
      map.hasFeatureAtPixel(e.pixel) ? 'pointer' : '';
  });
}
