import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import {
  initCharts,
  loadChartData,
  selectFormState,
  selectUnitsWithData,
  setEndDateTime,
  setStartDateTime,
} from './chartsSlice';
import { debounce } from 'lodash';
import 'moment-timezone';
import LineChart from '../../components/charts/LineChart';
import { Datalogger } from '../../api';
import Panel from '../../components/layout/Panel';
import DataloggerMap from '../dataloggerMap/DataloggerMap';
import "react-datepicker/dist/react-datepicker.css";
import './DataloggerCharts.css';
import DateTimeRangeForm from '../../components/forms/DateTimeRangeForm';

interface DataloggerChartsProps {
  datalogger: Datalogger;
}
const DataloggerCharts = ({ datalogger }: DataloggerChartsProps) => {
  const dispatch = useDispatch();
  const unitsWithData = useSelector(selectUnitsWithData);
  const { startDateTime, endDateTime } = useSelector(selectFormState);

  const debouncedLoadChartData = useMemo(() => {
    return debounce(() => {
      dispatch(loadChartData());
    }, 2000);
  }, [dispatch])
  let loggerCoordinates = [0, 0];

  useEffect(() => {
    dispatch(initCharts(datalogger));
  }, [dispatch, datalogger]);

  if (datalogger.lon && datalogger.lat) {
    loggerCoordinates = [datalogger.lon, datalogger.lat];
  }

  return (
    <div>
      <Panel>
        <DateTimeRangeForm
          currentStart={startDateTime}
          currentEnd={endDateTime}
          onChangeStart={(date) => {
            dispatch(setStartDateTime(date.format()));
            debouncedLoadChartData();
          }}
          onChangeEnd={(date) => {
            dispatch(setEndDateTime(date.format()));
            debouncedLoadChartData();
          }}
        />
      </Panel>
      <Row>
        <Col xl={6}>
          <Panel>
            <DataloggerMap dataloggers={[datalogger]} zoom={9} coordinates={loggerCoordinates} />
          </Panel>
        </Col>
        {unitsWithData.map((dataSet: any) => (
          <Col xl={6} key={dataSet.label + dataSet.symbol}>
            <Panel>
              <h3>{dataSet.label} {dataSet.symbol} {dataSet.loading && <Spinner animation="border" size="sm" />}</h3>
              <LineChart datasets={[dataSet]} />
            </Panel>
          </Col>
        ))}
      </Row>
    </div>
  )
};

export default DataloggerCharts;
