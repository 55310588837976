import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { color } from 'features/dataloggerCharts/legacyConfig';

am4core.useTheme(am4themes_animated);

const InclinometerChart = ({ chartId, chartData, horizontalInclinometer }: any) => {
  const xAxisMax = 10;
  const maxValue = chartData ? Math.max(...chartData.map((data: any) => data.value ? Math.abs(data.value) : 0)) : xAxisMax;

  useEffect(() => {
    let chart = am4core.create(chartId, am4charts.XYChart);
    // Add data
    chart.data = chartData;
    chart.numberFormatter.numberFormat = "####.00";

    let depthAxis = horizontalInclinometer ?
      chart.xAxes.push(new am4charts.CategoryAxis())
      :
      chart.yAxes.push(new am4charts.CategoryAxis());
    depthAxis.dataFields.category = "category";
    depthAxis.title.text = horizontalInclinometer ?
      'Etäisyys (m)'
      :
      'Syvyys (m)';
    //depthAxis.renderer.inversed = true;
    depthAxis.renderer.minGridDistance = 1;
    depthAxis.renderer.grid.template.disabled = true;
    if (horizontalInclinometer) depthAxis.renderer.minGridDistance = 50;

    let valueAxis = horizontalInclinometer ?
      chart.yAxes.push(new am4charts.ValueAxis())
      :
      chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.numberFormatter.numberFormat = "##' mm'";
    valueAxis.min = maxValue > xAxisMax ? -maxValue : -xAxisMax;
    valueAxis.max = maxValue > xAxisMax ? maxValue : xAxisMax;

    //let timestamp1 = "";
    let timestamp2 = "";
    if (chartData?.length > 0) {
      //const d1 = new Date(chartData[0].timestamp1);
      const d2 = new Date(chartData[0].timestamp2);
      //timestamp1 = `${d1.getDate()}.${d1.getMonth()+1}.${d1.getFullYear()}`;
      timestamp2 = `${d2.getDate()}.${d2.getMonth()+1}.${d2.getFullYear()} ${("0" + d2.getHours()).slice(-2)}:${("0" + d2.getMinutes()).slice(-2)}`;
    }

    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries());
    //series1.name = timestamp1;
    series1.name = timestamp2;
    if (horizontalInclinometer) {
      series1.dataFields.valueY = "value";
      series1.dataFields.categoryX = "category";
      series1.tooltipText = "{valueY.value}";
    } else {
      series1.dataFields.valueX = "value";
      series1.dataFields.categoryY = "category";
      series1.tooltipText = "{valueX.value}";
    }
    series1.strokeWidth = 3;
    series1.stroke = am4core.color(color.primary._300);
    if (series1.tooltip) {
      series1.tooltip.getFillFromObject = false;
      series1.tooltip.background.fill = am4core.color(color.primary._600);
    }

    chart.legend = new am4charts.Legend();
    // Dont remove these yet. We might need these
    // let series2 = chart.series.push(new am4charts.LineSeries());
    // series2.name = timestamp2
    // series2.dataFields.valueX = "value2";
    // series2.dataFields.categoryY = "category";
    // series2.strokeWidth = 3;
    // series2.stroke = am4core.color(color.danger._300);
    // series2.tooltipText = "{valueX.value}";
    // if (series2.tooltip) {
    //   series2.tooltip.getFillFromObject = false;
    //   series2.tooltip.background.fill = am4core.color(color.danger._600);
    // }

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    // Bullets
    const bullet = new am4charts.CircleBullet();
    bullet.circle.strokeWidth = 6;
    bullet.circle.fill = am4core.color("#fff");
    //bullet.circle.radius = 4;
    bullet.properties.scale = 0.5;

    const bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1;

    series1.bullets.push(bullet);
    //series2.bullets.push(bullet);

    return () => {
      chart.dispose();
    };

  }, [chartData, chartId, maxValue, horizontalInclinometer]);
  return (
    <div id={chartId} style={{ height: "700px" }}></div>
  );
}

export default InclinometerChart;
