import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Row from 'react-bootstrap/Row';
import { Col, Container, Table } from 'react-bootstrap';

const am4themes_customTheme = (target: any) => {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#FFBB11"),
      am4core.color("#FF4400"),
      am4core.color("#AA1100"),
      am4core.color("#55AA00"),
      am4core.color("#0000FF"),
      am4core.color("#4B0082"),
    ];
  }
}

export interface WindRoseProps {
  data: any;
  chartId: string;
}

export interface DirectionPercentagesTableProps {
  percentages: any;
}

export interface SpeedPercentagesTableProps {
  percentages: any;
  speedTitles: any;
  calmPercent: number;
}

interface WindData {
  direction: number;
  speed: number;
}

const DirectionPercentagesTable = (props: DirectionPercentagesTableProps) => {
  const { percentages } = props;

  return (
    <Table striped bordered hover>
      <thead>
      <tr>
        <th colSpan={2}>Suuntajakauma</th>
      </tr>
      </thead>
      <tbody>
      {percentages.map((p: any, i: number) => (
        <tr key={i}>
          <td>{p.direction}</td>
          <td>{p.percent}%</td>
        </tr>
      ))}
      </tbody>
    </Table>
  );
}

const SpeedPercentagesTable = (props: SpeedPercentagesTableProps) => {
  const { percentages, speedTitles, calmPercent } = props;
  const colorSet = new am4core.ColorSet();

  return (
    <Table striped bordered hover>
      <thead>
      <tr>
        <th colSpan={3}>Nopeusjakauma</th>
      </tr>
      </thead>
      <tbody>

      {percentages.map((p: any, i: number) => {
        const color = colorSet.next();
        return (
          <tr key={i}>
            <td><div style={{height: "15px", width: "30px", background: color.hex}} /></td>
            <td>{speedTitles[i]}</td>
            <td>{p.toFixed(1)}%</td>
          </tr>
        )
      })}

      <tr>
        <td colSpan={2}>Tyyntä</td>
        <td>{calmPercent.toFixed(2)}%</td>
      </tr>
      </tbody>
    </Table>
  );
}

interface WindDirectionData {
  N: WindData[];
  NNE: WindData[];
  NE: WindData[];
  ENE: WindData[];
  E: WindData[];
  ESE: WindData[];
  SE: WindData[];
  SSE: WindData[];
  S: WindData[];
  SSW: WindData[];
  SW: WindData[];
  WSW: WindData[];
  W: WindData[];
  WNW: WindData[];
  NW: WindData[];
  NNW: WindData[];
}

function getWindroseData(windDirectionData: any, windSpeedData: any) {
  const chartData = [];
  const directionPercentages = [];
  const speedPercentages = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
  let calmCount = 0;
  let numberOfMeasurements = 0;
  if (windDirectionData) {
    numberOfMeasurements = windDirectionData.length;
  }

  const filteredWindDirectionData: WindDirectionData = {
    N: [],
    NNE: [],
    NE: [],
    ENE: [],
    E: [],
    ESE: [],
    SE: [],
    SSE: [],
    S: [],
    SSW: [],
    SW: [],
    WSW: [],
    W: [],
    WNW: [],
    NW: [],
    NNW: [],
  };

  if (windDirectionData) {
    windDirectionData.forEach((d: any[], i: number) => {
      if (windDirectionData.length < 1 || windSpeedData.length < 1) return
      if (!windDirectionData[i] || !windSpeedData[i]) return

      const direction = windDirectionData[i][1];
      const speed = windSpeedData[i][1];
      const windData: WindData = {
        direction: direction,
        speed: speed,
      }

      if (direction > 348.750 || direction <= 11.250) {
        filteredWindDirectionData['N'].push(windData);
      } else if (direction > 11.250 && direction <= 33.750) {
        filteredWindDirectionData['NNE'].push(windData);
      } else if (direction > 33.750 && direction <= 56.250) {
        filteredWindDirectionData['NE'].push(windData);
      } else if (direction > 56.250 && direction <= 78.750) {
        filteredWindDirectionData['ENE'].push(windData);
      } else if (direction > 78.750 && direction <= 101.250) {
        filteredWindDirectionData['E'].push(windData);
      } else if (direction > 101.250 && direction <= 123.750) {
        filteredWindDirectionData['ESE'].push(windData);
      } else if (direction > 123.750 && direction <= 146.250) {
        filteredWindDirectionData['SE'].push(windData);
      } else if (direction > 146.250 && direction <= 168.750) {
        filteredWindDirectionData['SSE'].push(windData);
      } else if (direction > 168.750 && direction <= 191.250) {
        filteredWindDirectionData['S'].push(windData);
      } else if (direction > 191.250 && direction <= 213.750) {
        filteredWindDirectionData['SSW'].push(windData);
      } else if (direction > 213.750 && direction <= 236.250) {
        filteredWindDirectionData['SW'].push(windData);
      } else if (direction > 236.250 && direction <= 258.750) {
        filteredWindDirectionData['WSW'].push(windData);
      } else if (direction > 258.750 && direction <= 281.250) {
        filteredWindDirectionData['W'].push(windData);
      } else if (direction > 281.250 && direction <= 302.750) {
        filteredWindDirectionData['WNW'].push(windData);
      } else if (direction > 302.750 && direction <= 326.250) {
        filteredWindDirectionData['NW'].push(windData);
      } else if (direction > 326.250 && direction <= 348.750) {
        filteredWindDirectionData['NNW'].push(windData);
      }
    })
  }

  for (let [direction, windDatas] of Object.entries(filteredWindDirectionData)) {
    const directionCount = windDatas.length;
    let directionTotalPercent = 0.0;
    const speeds = [0, 0, 0, 0, 0, 0]
    if (directionCount > 0) {
      directionTotalPercent = (directionCount*100/numberOfMeasurements);

      for(let d of windDatas) {
        if (d.speed >= 0.1 && d.speed <= 2.0) {
          speeds[0]++;
        } else if (d.speed > 2.0 && d.speed <= 4.0) {
          speeds[1]++;
        } else if (d.speed > 4.0 && d.speed <= 6.0) {
          speeds[2]++;
        } else if (d.speed > 6.0 && d.speed <= 8.0) {
          speeds[3]++;
        } else if (d.speed > 8.0 && d.speed <= 10.0) {
          speeds[4]++;
        } else if (d.speed > 10.0) {
          speeds[5]++;
        } else {
          calmCount++
        }
      }
    }

    directionPercentages.push({
      direction: direction,
      percent: directionTotalPercent.toFixed(2)
    })

    const value1 = directionTotalPercent * (speeds[0] / directionCount)
    const value2 = directionTotalPercent * (speeds[1] / directionCount)
    const value3 = directionTotalPercent * (speeds[2] / directionCount)
    const value4 = directionTotalPercent * (speeds[3] / directionCount)
    const value5 = directionTotalPercent * (speeds[4] / directionCount)
    const value6 = directionTotalPercent * (speeds[5] / directionCount)

    if (!isNaN(value1)) speedPercentages[0] += Number(value1);
    if (!isNaN(value2)) speedPercentages[1] += Number(value2);
    if (!isNaN(value3)) speedPercentages[2] += Number(value3);
    if (!isNaN(value4)) speedPercentages[3] += Number(value4);
    if (!isNaN(value5)) speedPercentages[4] += Number(value5);
    if (!isNaN(value6)) speedPercentages[5] += Number(value6);

    chartData.push(
      {
        category: direction,
        value1: value1.toFixed(2), // 0.1 - 2.0
        value2: value2.toFixed(2), // 2.0 - 4.0
        value3: value3.toFixed(2), // 4.0 - 6.0
        value4: value4.toFixed(2), // 6.0 - 8.0
        value5: value5.toFixed(2), // 8.0 - 10.0
        value6: value6.toFixed(2) // 10.0 - inf.
      }
    )
  }
  return {
    chartData,
    directionPercentages,
    speedPercentages,
    calmCount,
    numberOfMeasurements
  };
}

const WindRose = (props: WindRoseProps) => {
  const { data, chartId } = props;
  const windDirectionData = data.units[38033];
  const windSpeedData = data.units[38037];
  const {
    chartData,
    directionPercentages,
    speedPercentages,
    calmCount,
    numberOfMeasurements
  } = getWindroseData(windDirectionData, windSpeedData);
  const speedTitles = ["0.1 - 2.0 m/s", "2.0 - 4.0 m/s", "4.0 - 6.0 m/s", "6.0 - 8.0 m/s", "8.0 - 10.0 m/s", "> 10.0 m/s"];

  useEffect(() => {
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_customTheme);
    let chart = am4core.create(chartId, am4charts.RadarChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.data = chartData;

    let categoryAxis = chart.xAxes.push(
      new am4charts.CategoryAxis<am4charts.AxisRendererCircular>()
    );
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.tooltipLocation = 0.5;

    let valueAxis = chart.yAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererRadial>()
    );
    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }
    valueAxis.dx = 15;

    speedTitles.forEach((title, i) => {
      let newSeries = chart.series.push(new am4charts.RadarColumnSeries());
      newSeries.columns.template.tooltipText = "{name}: {valueY.value}%";
      newSeries.columns.template.width = am4core.percent(80);
      newSeries.name = title;
      newSeries.dataFields.categoryX = "category";
      newSeries.dataFields.valueY = `value${i+1}`;
      newSeries.stacked = true;
      newSeries.rotation = -11.25;
    })

    chart.seriesContainer.zIndex = -1;

    return () => {
      chart.dispose();
    };

  }, [chartId, chartData, speedTitles ])


  return (
    <Container fluid>
      <Row>
        <Col lg={8}>
          <div id={chartId} style={{ width: "100%", height: "100%" }} />
        </Col>
        <Col md={2}>
          <DirectionPercentagesTable percentages={directionPercentages} />
        </Col>
        <Col md={2}>
          <SpeedPercentagesTable
            percentages={speedPercentages}
            speedTitles={speedTitles}
            calmPercent={(calmCount/numberOfMeasurements*100)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          Mittausten määrä: {numberOfMeasurements}
        </Col>
      </Row>
    </Container>
  );

}

export default WindRose;
