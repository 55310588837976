import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Dashboard,
  DashboardDataResponse,
  getDashboard,
  getDashboardData,
  PartialDashboard,
  UserStateResponse,
} from 'api';
import { AppThunk } from 'app/store';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { RootState } from '../../app/rootReducer';

type DataDisplayType = 'period' | 'range';
interface DashboardsState {
  dashboards: PartialDashboard[];
  isLoading: boolean;
  currentDashboard: Dashboard | null;
  currentDashboardData: DashboardDataResponse;
  dataDisplayType: DataDisplayType;
  dataPeriodMinutes: number;
  dataRangeStart?: string;
  dataRangeEnd?: string;
  timesUpdated: number;
}
const emptyDashboardDataResponse: DashboardDataResponse = {
  units: {},
  formulas: {},
  meta: {
    updatedOn: '1970-01-01T00:00Z',
    start: '1970-01-01T00:00Z',
    end: '1970-01-01T00:00Z',
    dataStart: null,
    dataEnd: null,
    closestToAlarm: {
      title: '',
      value: null
    }
  },
  widgets: {}
}
const initialState: DashboardsState = {
  dashboards: [],
  isLoading: false,
  currentDashboard: null,
  currentDashboardData: emptyDashboardDataResponse,
  dataDisplayType: 'period',
  dataPeriodMinutes: 45,
  dataRangeStart: undefined,
  dataRangeEnd: undefined,
  timesUpdated: 0,
};

const createDashboardStateSelector = (id: string) => (rootState: any): Omit<DashboardsState, 'dashboards'> => {
  const dashboardsState = (rootState.dashboards as DashboardsState)
  if (!dashboardsState.currentDashboard || dashboardsState.currentDashboard.id !== id) {
    return {
      isLoading: true,
      currentDashboard: null,
      currentDashboardData: emptyDashboardDataResponse,
      dataPeriodMinutes: dashboardsState.dataPeriodMinutes,
      dataDisplayType: dashboardsState.dataDisplayType,
      dataRangeStart: dashboardsState.dataRangeStart,
      dataRangeEnd: dashboardsState.dataRangeEnd,
      timesUpdated: dashboardsState.timesUpdated,
    }
  }
  return {
    isLoading: dashboardsState.isLoading,
    currentDashboard: dashboardsState.currentDashboard,
    currentDashboardData: dashboardsState.currentDashboardData,
    dataPeriodMinutes: dashboardsState.dataPeriodMinutes,
    dataDisplayType: dashboardsState.dataDisplayType,
    dataRangeStart: dashboardsState.dataRangeStart,
    dataRangeEnd: dashboardsState.dataRangeEnd,
    timesUpdated: dashboardsState.timesUpdated,
  }
}
// Cache selectors so we can be sure they are memoized...
const dashboardSelectorsById: Record<string, any> = {};
export const selectDashboardState = (id: string): ((rootState: any) => Omit<DashboardsState, 'dashboards'>) => {
  let obj = dashboardSelectorsById[id];
  if(obj) {
    return obj;
  }
  obj = createDashboardStateSelector(id);
  dashboardSelectorsById[id] = obj;
  return obj;
}

const dashboards = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    startInitialization(state) {
      state.currentDashboard = null;
      state.currentDashboardData = initialState.currentDashboardData;
      state.isLoading = true;
      state.timesUpdated = 0;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    initializationSuccess(state) {
      state.isLoading = false;
    },
    initializationError(state) {
      state.isLoading = false;
    },
    dashboardReceived(state, action: PayloadAction<Dashboard>) {
      const dashboard = action.payload;
      state.currentDashboard = dashboard;
      if (dashboard.data_period) {
        state.dataPeriodMinutes = getDataPeriodMinutes(dashboard.data_period);
      }
    },
    dashboardDataReceived(state, action: PayloadAction<DashboardDataResponse>) {
      state.currentDashboardData = action.payload;
      state.timesUpdated += 1;
    },
    bumpTimesUpdated(state) {
      state.timesUpdated += 1;
    },
    setDataPeriod(state, action: PayloadAction<number>) {
      state.dataPeriodMinutes = action.payload;
      state.timesUpdated = 0;
    },
    setDataDisplayType(state, action: PayloadAction<DataDisplayType>) {
      state.dataDisplayType = action.payload;
      state.timesUpdated = 0;
    },
    setDataRangeStart(state, action: PayloadAction<string|Date|number|null>) {
      state.dataRangeStart = action.payload ? moment(action.payload).format() : '';
      state.timesUpdated = 0;
    },
    setDataRangeEnd(state, action: PayloadAction<string|Date|number|null>) {
      state.dataRangeEnd = action.payload ? moment(action.payload).format() : '';
      state.timesUpdated = 0;
    },
  },
  extraReducers: {
    'authentication/loginSuccess': (state, action: PayloadAction<UserStateResponse>) => {
      state.dashboards = action.payload.dashboards;
    }
  }
})

function getDataPeriodMinutes(dataPeriod: Record<string, number>) {
  let minutes = 0;
  if(dataPeriod.minutes) {
    minutes += dataPeriod.minutes;
  }
  if(dataPeriod.hours) {
    minutes += dataPeriod.hours * 60;
  }
  if(dataPeriod.seconds) {
    minutes += Math.floor(dataPeriod.seconds / 60);
  }
  if(dataPeriod.days) {
    minutes += dataPeriod.days * 24 * 60;
  }
  return minutes;
}

// internal actions
const {
  startInitialization,
  initializationSuccess,
  initializationError,
  dashboardReceived,
  dashboardDataReceived,
  startLoading,
  stopLoading,
  bumpTimesUpdated,
} = dashboards.actions;

let updateIntervalId: number | null = null;

// external actions

export const {
  setDataPeriod,
  setDataDisplayType,
  setDataRangeStart,
  setDataRangeEnd,
} = dashboards.actions;

export const initDashboard = (
  id: string,
): AppThunk => async (dispatch, getState) => {
  if(updateIntervalId) {
    clearInterval(updateIntervalId);
    updateIntervalId = null;
  }

  dispatch(startInitialization())

  let dashboard;
  try {
    dashboard = await getDashboard(id);
  } catch (e) {
    console.error(e);
    dispatch(initializationError());
    return;
  }
  dispatch(dashboardReceived(dashboard))

  let dashboardData;
  try {
    dashboardData = await getDashboardData(id, getDashboardDataOpts(getState().dashboards));
  } catch (e) {
    console.error(e);
    dispatch(initializationError());
    return;
  }
  dispatch(dashboardDataReceived(dashboardData))

  const updateIntervalMs = dashboard.update_interval || 60 * 1000;

  // if we run concurrent initDashboards, it's possible that this is already set.
  // in that case, clear it so that we only update one dashboard at a time.
  // TODO: this needs a real fix. this sux
  if(updateIntervalId) {
    clearInterval(updateIntervalId);
  }

  updateIntervalId = setInterval(async () => {
    dispatch(updateCurrentDashboardData());
  }, updateIntervalMs) as any;

  dispatch(initializationSuccess());
}

export const updateCurrentDashboardData = (): AppThunk => async (dispatch, getState) => {
  await updateCurrentDashboardDataDebounced(dispatch, getState);
}
let updateCurrentDashboardDataDebounced = async (dispatch: any, getState: () => RootState) => {
  // TODO: this can be optimized if we don't have dataDisplayType === 'range' and if end is not in the future
  const rootState = getState();
  const dashboardState = rootState.dashboards;
  const currentDashboard = dashboardState.currentDashboard;
  if(!currentDashboard) {
    return;
  }

  const id = currentDashboard.id;
  if(dashboardState.dataDisplayType === 'range' && dashboardState.timesUpdated >= 1) {
    console.log(
      `Not updating dashboard: ${id} because time range is selected and already updated ${dashboardState.timesUpdated} times`
    );
    return;
  }

  const updateOpts = getDashboardDataOpts(dashboardState);

  console.log(`Updating dashboard: ${id} with opts:`, updateOpts);

  // HACK: time period range updates might take a long time. Bump this thing to stop further updates.
  if(dashboardState.dataDisplayType === 'range') {
    dispatch(bumpTimesUpdated());
  }

  let dashboardData;
  dispatch(startLoading())
  try {
    dashboardData = await getDashboardData(id, updateOpts);
  } catch (e) {
    console.error('Error updating dashboard:', e);
    dispatch(stopLoading())
    return;
  }

  // TODO: check the other options here too, it's possible that we have selected a different range and already
  // queued a new update, which means that this must be aborted
  const newDashboard = getState().dashboards.currentDashboard;
  if(!newDashboard || newDashboard.id !== id) {
    console.log('Dashboard changed after updating, not doing anything');
    return;
  }
  dispatch(dashboardDataReceived(dashboardData));
  dispatch(stopLoading());
}
updateCurrentDashboardDataDebounced = debounce(updateCurrentDashboardDataDebounced, 100);

function getDashboardDataOpts(state: DashboardsState) {
  const {
    dataPeriodMinutes,
    dataDisplayType,
    dataRangeStart,
    dataRangeEnd,
  } = state;
  if(dataDisplayType === 'period') {
    return {
      dataPeriod: dataPeriodMinutes || 45,
    }
  } else if (dataRangeStart && dataRangeEnd) {
    return {
      start: dataRangeStart,
      end: dataRangeEnd,
    }
  } else {
    return {
      dataPeriod: 45,
    }
  }
}

// reducer
export default dashboards.reducer;
