import React from 'react';
import './App.css';
import Layout from "components/layout/Layout";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { isIE } from 'compat';

import { RootState } from "./rootReducer";
import UnauthenticatedFrontPage from "pages/UnauthenticatedFrontPage";
import DataloggerListPage from 'pages/DataloggerListPage';
import DataloggerDetailsPage from 'pages/DataloggerDetailsPage';
import TiltDemo2 from 'pages/TiltDemo2';
import DashboardListPage from 'pages/DashboardListPage';
import DashboardDetailsPage from 'pages/DashboardDetailsPage';
import IeWarningBanner from 'components/utils/IeWarningBanner';

function App() {
  const [showIeWarning, setShowIeWarning] = React.useState(isIE);
  const user = useSelector(
    (state: RootState) => state.authentication.user
  );
  const dashboards = useSelector(
    (state: RootState) => state.dashboards.dashboards
  );

  let defaultRedirect = '/dataloggers/';
  if (dashboards.length > 0) {
    //defaultRedirect = `/dashboards/${dashboards[0].id}`
    defaultRedirect = `/dashboards/`;
  }

  return (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      {!user ? (
        <UnauthenticatedFrontPage/>
      ) : (
        <Layout user={user} dashboards={dashboards} showIeWarning={showIeWarning}>
          {showIeWarning ? <IeWarningBanner setShowIeWarning={setShowIeWarning} /> : ""}
          <Switch>
            <Route exact path="/dataloggers/">
              <DataloggerListPage />
            </Route>
            <Route exact path="/dataloggers/:idcode/">
              <DataloggerDetailsPage />
            </Route>
            <Route exact path="/dashboards/">
              <DashboardListPage dashboards={dashboards} />
            </Route>
            <Route exact path="/dashboards/:id/">
              <DashboardDetailsPage />
            </Route>
            <Route exact path="/tilt-demo/">
              <TiltDemo2 />
            </Route>
            <Route exact path="/">
              <Redirect to={defaultRedirect} />
            </Route>
            <Route path="*">
              404 Not found
            </Route>
          </Switch>
        </Layout>
      )}
    </Router>
  )
}

export default App;
