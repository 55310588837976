import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import { Col, Container, Row } from 'react-bootstrap';
import { DashboardDataResponse, Widget } from '../../api';

interface ColumnChartProps {
  widget: Widget;
  data: DashboardDataResponse;
  chartId: string;
}

interface ChartData {
  date: string;
  value: number;
}

const getMonthName = (monthNumber: number) => {
  const months = [
    "Tammikuu",
    "Helmikuu",
    "Maaliskuu",
    "Huhtikuu",
    "Toukokuu",
    "Kesäkuu",
    "Heinäkuu",
    "Elokuu",
    "Syyskuu",
    "Lokakuu",
    "Marraskuu",
    "Joulukuu"
  ]

  return months[monthNumber-1];
}

const ColumnChart = (props: ColumnChartProps) => {
  const { widget, data, chartId } = props;
  const symbol = widget.data.sources[0].symbol;
  const rainData = data.units[38038];
  const filteredData: any = [];
  const chartData: ChartData[] = [];

  if (rainData) {
    rainData.forEach((d: any[]) => {
      const date = d[0].split("T")[0].split("-");
      const title = `${getMonthName(parseInt(date[1]))} ${date[0]}`;
      const measurement = d[1];
      if (!filteredData[title]) {
        filteredData[title] = measurement;
      } else if (measurement > 0) {
        filteredData[title] += measurement;
      }
    })
  }

  for (let d in filteredData) {
    chartData.push({
      date: d,
      value: filteredData[d]
    })
  }

  useEffect(() => {
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_frozen);
    let chart = am4core.create(chartId, am4charts.XYChart);
    chart.data = chartData;
    let title = chart.titles.create();
    title.text = "Sadanta";
    title.fontSize = 25;
    title.marginBottom = 30;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = symbol;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.name = "Visits";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/] " + symbol;
    series.columns.template.fillOpacity = .8;

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    return () => {
      chart.dispose();
    };

  }, [chartId, chartData, symbol])

  return (
    <Container fluid>
      <Row>
        <Col>
          <div id={chartId} style={{ height: "600px" }} />
        </Col>
      </Row>
    </Container>
  )
}

export default ColumnChart;
