import React, { useEffect, useRef, useState } from "react";
import OlMap from "ol/Map.js";
import View from "ol/View.js";
import * as proj from 'ol/proj';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import MapLayer from "./mapLayer";
import { createMarkers } from "./mapUtils";
import { Datalogger } from '../../api';
import './map.css';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import { useTranslation } from 'react-i18next';
import { isIE } from 'compat';

const LayerSwitcher = require('ol-layerswitcher');

interface DataloggerMapProps {
  dataloggers: Datalogger[];
  zoom?: number;
  coordinates?: number[];
}

const DataloggerMapInner = ({ dataloggers, zoom = 5, coordinates = [0, 0] }: DataloggerMapProps) => {
  let center = [0, 0];
  //coordinates[0] !== 0 && coordinates[1] !== 0 ? center = proj.fromLonLat(coordinates) : center = proj.fromLonLat([25.4706, 65.0120])
  //const center = proj.fromLonLat(coordinates);
  const [mapCreated, setMapCreated] = useState(false);
  const mounted = useRef(null);
  const { t } = useTranslation()

  if (coordinates[0] === 0 || coordinates[1] === 0) {
    center = proj.fromLonLat([25.4, 65.0]);
    zoom = 4;
  } else {
    center = proj.fromLonLat(coordinates);
  }

  useEffect(() => {
    if (mapCreated) {
      return;
    }
    const map = new OlMap({
      target: mounted.current || undefined,
      layers: MapLayer,
      view: new View({
        projection: 'EPSG:3857',
        center: center,
        zoom: zoom
      }),
      controls: defaultControls().extend([
        new LayerSwitcher(),
        new FullScreen()
      ]),
    })

    if (dataloggers.length > 0) {
      createMarkers(dataloggers, map, t);
    }
    setMapCreated(true);
  }, [mapCreated, dataloggers, center, zoom, t]);

  return (
    <div className="map-container" ref={mounted} />
  );
}


const DataloggerMap = (props: DataloggerMapProps) => {
  const { t } = useTranslation()

  if (isIE) {
    return <div><small className="text-muted">({t('Maps are not supported on Internet Explorer')})</small></div>;
  }
  return <DataloggerMapInner {...props} />;
}
export default DataloggerMap;
