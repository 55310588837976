import * as React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { Datapoint, TerseDatapoint } from '../../api';
import { toTerseDatapoints } from '../../api/utils';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

export interface GaugeProps {
  title?: string;
  symbol?: string;
  data: Datapoint[] | TerseDatapoint[];
  middle: number;
  green: number;
  yellow: number;
  red: number;
  needle_in_middle: boolean;
  loading?: boolean;
  grayscale_gauge?: boolean;
  number_of_decimals?: number;
  green_start_value?: number;
}
const Gauge = (props: GaugeProps) => {
  const {
    middle,
    green,
    yellow,
    red,
    needle_in_middle,
    loading = false,
    symbol = '',
    grayscale_gauge,
    number_of_decimals,
    green_start_value,
  } = props;
  const terseData = toTerseDatapoints(props.data);
  const values = terseData.map(d => d[1]);
  const lastValue = values[values.length - 1];
  const { t } = useTranslation();
  let segmentColors, max, segmentSettings;
  let min = green_start_value ? green_start_value : 0;

  if (!needle_in_middle) {
    max = red;
    if (grayscale_gauge) {
      segmentSettings = {
        maxSegmentLabels: 4,
        segments: 1000,
        startColor: "#E8E8E8",
        endColor: "#C0C0C0",
      }
    } else {
      segmentColors = [
        '#00d49c',
        '#fddf08',
        '#ff005c',
        // If the colors above are not beautiful enought, try these
        // '#40c5af',
        // '#ffc83d',
        // '#ef6950',
      ];
      segmentSettings = {
        segments: segmentColors.length,
        segmentColors: segmentColors,
        customSegmentStops: [
          min,
          green,
          yellow,
          red,
        ]
      }
    }
  } else {
    segmentColors = [
      '#ff471a',
      '#ecdb23',
      '#6ad72d',
      '#ecdb23',
      '#ff471a',
    ];
    min = middle - red;
    max = middle + red;
    segmentSettings = {
      segments: segmentColors.length,
      segmentColors: segmentColors,
      customSegmentStops: [
        min,
        middle - yellow,
        middle - green,
        middle + green,
        middle + yellow,
        max,
      ]
    }
  }

  return (
    <div style={{
      width: '100%',
    }}>
      {props.title && (
        <h4 className="text-center">{props.title}</h4>
      )}
      <div style={{
        width: '100%',
        position: 'relative',
        paddingBottom: 'calc(50% + 30px)',
      }}>
        <div style={{
          position: 'absolute',
          width: '100%',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        }}>
          {(lastValue !== undefined) ? (
            <ReactSpeedometer
              key={`has-data`}
              fluidWidth
              minValue={min}
              maxValue={max}
              value={Math.min(Math.max(lastValue, min), max) /* We limit this to the visible range of the gauge */}
              ringWidth={30}
              {...segmentSettings}
              currentValueText={`${lastValue.toFixed(number_of_decimals)} ${symbol}`}
              labelFontSize="10px"
              //valueFormat=".2f"
            />
          ) : (
              <>
                <ReactSpeedometer
                  key={`no-data`}
                  fluidWidth
                  minValue={min}
                  maxValue={max}
                  value={undefined}
                  ringWidth={30}
                  {...segmentSettings}
                  needleHeightRatio={0}
                  needleColor="transparent"
                  currentValueText={
                    loading ? (
                      ''
                    ) : (
                        t('No data')
                      )
                  }
                  labelFontSize="10px"
                  //valueFormat=".2f"
                />
                {loading && (
                  <div style={{
                    position: 'absolute',
                    textAlign: 'center',
                    width: '100%',
                    left: '0',
                    right: '0',
                    bottom: '0',
                  }}>
                    <Spinner animation="border" />
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  )
}
export default Gauge;
