import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import moment from 'moment-timezone';
import 'moment/locale/fi';

import './LanguageSwitcher.scss';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.languages[0];
  moment.locale(selectedLanguage);

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
    moment.locale(locale);
    localStorage.setItem('selectedLanguage', locale);
  }

  return (
    <div className={classNames('LanguageSwitcher', `selected-language-${selectedLanguage}`)}>
      <a href="#fi" className="language language-fi" onClick={() => changeLanguage('fi')}>Suomi</a>
      <a href="#en" className="language language-en" onClick={() => changeLanguage('en')}>Eng</a>
    </div>
  )
}

export default LanguageSwitcher
