import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { unitOfTime } from 'moment-timezone';
import { Datalogger, Datapoint, getDataloggerUnits, getDatapoints, Unit, TerseDatapoint } from '../../api';
import { AppThunk } from '../../app/store';

interface ChartsState {
  datalogger: Datalogger | null;
  units: Unit[];
  unitData: Record<number, UnitDataWrapper>;
  initializationInProgress: boolean;
  error: string;
  startDateTime: string;
  endDateTime: string;
}
interface UnitDataWrapper {
  data: Datapoint[];
  loading: boolean;
}

const initialState: ChartsState = {
  datalogger: null,
  units: [],
  unitData: {},
  initializationInProgress: false,
  error: '',
  startDateTime: '',
  endDateTime: '',
}

export interface UnitWithData {
  unit: Unit;
  data: Datapoint[];
  loading: boolean;
}

export interface UnitWithDataNew {
  label: string;
  data: TerseDatapoint[];
  symbol: string;
  loading: boolean;
}

export const selectUnitsWithData = (rootState: any): UnitWithDataNew[] => {
  const chartsState: ChartsState = rootState.charts;
  return chartsState.units.map((unit: Unit) => ({
    'label': unit.name,
    'data':
      chartsState.unitData[unit.id].data.map(data => [
        data.timestamp,
        data.value
      ]),
    'symbol': unit.symbol,
    'loading': chartsState.unitData[unit.id].loading
  }));
}
export const selectUnitsWithDataByUniquename = (rootState: any): Record<string, UnitWithData> => {
  return selectUnitsWithDataByField(rootState, 'uniquename')
}
export const selectUnitsWithDataByField = (rootState: any, field: keyof Unit): Record<string, UnitWithData> => {
  const chartsState: ChartsState = rootState.charts;
  const ret: Record<string, UnitWithData> = {};
  for(const unit of chartsState.units) {
    const fieldValue = (unit[field] || '').toString();
    ret[fieldValue] = {
      unit,
      ...chartsState.unitData[unit.id],
    }
  }
  return ret;
}
type ChartsFormState = Pick<ChartsState, 'startDateTime' | 'endDateTime'>;
export const selectFormState = (rootState: any): ChartsFormState => {
  const chartsState: ChartsState = rootState.charts;
  return {
    endDateTime: chartsState.endDateTime,
    startDateTime: chartsState.startDateTime,
  }
}

interface TimeDuration {
  amount: number;
  unit: unitOfTime.DurationConstructor;
}

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    startInitialization(
      state,
      action: PayloadAction<{
        datalogger: Datalogger,
        now: string,
        startDateTimeFromEnd?: TimeDuration,
      }>
    ) {
      const { datalogger, now } = action.payload;

      state.initializationInProgress = true;
      state.error = '';
      state.units = [];
      state.unitData = {};
      state.datalogger = datalogger;
      state.endDateTime = datalogger.lastmeasuring || now;

      let interval = action.payload.startDateTimeFromEnd;
      if(!interval) {
        interval = { amount: 7, unit: 'days' };
      }

      state.startDateTime = moment(
        state.endDateTime
      ).subtract(
        interval.amount,
        interval.unit
      ).format();
    },
    initializationSuccess(state) {
      state.initializationInProgress = false;
    },
    initializationError(state, action: PayloadAction<string>) {
      state.initializationInProgress = false;
      state.error = action.payload;
    },
    unitsReceived(state, action: PayloadAction<Unit[]>) {
      const units = action.payload;
      state.units = units;
      state.unitData = {};
      units.forEach(unit => {
        state.unitData[unit.id] = {data: [], loading: false};
      });
    },
    startLoadingUnitData(state, action: PayloadAction<Unit>) {
      const unit = action.payload;
      if(!state.unitData[unit.id]) {
        state.unitData[unit.id] = {data: [], loading: true};
      } else {
        state.unitData[unit.id].loading = true;
      }
    },
    unitDataReceived(state, action: PayloadAction<{unit: Unit, data: Datapoint[]}>) {
      const { unit, data } = action.payload;
      state.unitData[unit.id] = {
        data,
        loading: false
      };
    },
    setStartDateTime(state, action: PayloadAction<string|Date|number|null>) {
      state.startDateTime = action.payload ? moment(action.payload).format() : '';
    },
    setEndDateTime(state, action: PayloadAction<string|Date|number|null>) {
      state.endDateTime = action.payload ? moment(action.payload).format() : '';
    },
  },
})

// public actions
export const {
  setStartDateTime,
  setEndDateTime,
} = chartsSlice.actions;

// private actions
const {
  startInitialization,
  initializationError,
  initializationSuccess,
  unitsReceived,
  startLoadingUnitData,
  unitDataReceived,
} = chartsSlice.actions;

interface InitChartsOpts {
  startDateTimeFromEnd?: TimeDuration;
}
export const initCharts = (
  datalogger: Datalogger,
  opts?: InitChartsOpts,
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const existingDatalogger = state.charts.datalogger;
  if(existingDatalogger && existingDatalogger.idcode === datalogger.idcode) {
    console.log(`No need to re-init charts for datalogger ${datalogger.idcode}`);
    return;
  }
  console.log(`Initing charts for datalogger ${datalogger.idcode}`);
  const now = moment();
  dispatch(startInitialization({
    datalogger,
    now: now.format(),
    startDateTimeFromEnd: opts?.startDateTimeFromEnd,
  }));

  let units;
  try {
    units = await getDataloggerUnits(datalogger);
  } catch(e) {
    dispatch(initializationError('Error fetching datalogger units: ' + e.toString()));
    return;
  }

  dispatch(unitsReceived(units));

  await (loadChartData()(dispatch, getState, null));

  dispatch(initializationSuccess());
}

export const loadChartData = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const units = state.charts.units;

  for(const unit of units) {
    dispatch(startLoadingUnitData(unit));
  }

  const end = moment(state.charts.endDateTime);
  const start = moment(state.charts.startDateTime);
  const allUnitData = await getDatapoints({
    units,
    start,
    end,
  })

  for(const unit of units) {
    try {
      dispatch(unitDataReceived({
        unit,
        data: allUnitData.filter(d => d.unit === unit.url),
      }));
    } catch(e) {
      console.error(e);
    }
  }
}

export default chartsSlice.reducer;
