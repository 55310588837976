import * as React from 'react';
import { Datapoint, TerseDatapoint } from '../../api';
import { Line } from 'react-chartjs-2';
import { color } from 'features/dataloggerCharts/legacyConfig';
import { toTerseDatapoints } from 'api/utils';
import moment from 'moment-timezone';
import browserTimeZone from 'utils/browserTimeZone';
require('chartjs-plugin-zoom');

export interface DataSet {
  label: string;
  data: Datapoint[] | TerseDatapoint[];
  symbol?: string;
  type: string;
  hide_axis_label?: boolean;
  hidden_by_default?: boolean;
  axis_id?: number;
}
export interface DataChartProps {
  datasets: DataSet[];
  min?: number;
  max?: number;
  options?: any;
  number_of_decimals?: number;
  individual_axes?: boolean;
  redrawChart?: boolean,
  setRedrawChart?: any,
  timeZone?: string;
  axisTitle?: string;
  stepSize?: number;
}

const LineChart = (props: DataChartProps) => {
  const {
    datasets,
    min,
    max,
    number_of_decimals,
    individual_axes = false,
    redrawChart,
    setRedrawChart,
    timeZone = browserTimeZone,
    axisTitle,
    stepSize,
  } = props;

  const datasetProps = [
    {
      borderColor: color.primary._300,
      pointBackgroundColor: color.primary._500,
      pointBorderColor: color.primary._500,
    },
    {
      borderColor: color.danger._300,
      pointBackgroundColor: color.danger._500,
      pointBorderColor: color.danger._500,
    },
    {
      borderColor: color.success._300,
      pointBackgroundColor: color.success._500,
      pointBorderColor: color.success._500,
    },
    {
      borderColor: color.warning._300,
      pointBackgroundColor: color.warning._500,
      pointBorderColor: color.warning._500,
    },
    {
      borderColor: 'darkgray',
      pointBackgroundColor: 'darkgray',
      pointBorderColor: 'darkgray',
    },
  ]

  const chartData = {
    datasets: datasets.map((item, i) => (
      {
        label: item.label,
        hidden: item.hidden_by_default,
        fill: false,
        pointBorderWidth: 1,
        borderWidth: 3,
        hitRadius: 4,
        pointHoverRadius: 4,
        lineTension: 0,
        data: toTerseDatapoints(item.data).map((datapoint) => ({
          y: datapoint[1].toFixed(number_of_decimals),
          t: datapoint[0],
        })),
        yAxisID: item.axis_id ? item.axis_id : (individual_axes ? `y-${i}` : ""),
        ...item.type === "Alarm limit" ?
          {
            borderColor: color.warning._300,
            pointBackgroundColor: color.warning._500,
            pointBorderColor: color.warning._500,
            borderWidth: 2,
            pointRadius: 0,
          }
          :
          datasetProps[i] || {},
          ...item.data.length > 50 ?
          { pointRadius: 0, }
          :
          { pointRadius: 2, }
      }
    )),
  };

  let startDate, endDate;
  let timeUnit = 'minute';
  let hideLegends = true;
  if (chartData.datasets[0]?.data[0]) {
    hideLegends = false;
    startDate = moment(chartData.datasets[0].data[0].t);
    endDate = moment(chartData.datasets[0].data.slice(-1)[0].t);
    if ((endDate && startDate) && endDate.diff(startDate, 'hours') >= 24) {
      timeUnit = 'day';
    }
  }
  const yAxisItems = individual_axes ? datasets : (datasets.length ? [datasets[0]] : []);
  const dateDisplayFormats = {
    minute: 'HH:mm',
    day: 'D.M.YYYY'
  };
  let axis_label_count = 0;
  const chartOptions = {
    responsive: true,
    legend: {
      display: !hideLegends,
      position: 'bottom',
      labels: {
        filter: function (item: any, chart: any) {
          return props.datasets[item.datasetIndex].type !== "Alarm limit";
        }
      },
    },
    title: {
      display: false,
    },
    // TODO: disable animations or not?
    animation: {
      duration: 0,
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem: any) => {
          return moment.tz(tooltipItem[0].xLabel, timeZone).format('D.M.YYYY HH:mm:ss');
        },
        label: (tooltipItem: any) => {
          const dataset = datasets[tooltipItem.datasetIndex];
          const symbol = dataset.symbol;
          let ret = `${dataset.label}: ${tooltipItem.yLabel}`
          if (symbol) {
            ret += ' ' + symbol;
          }
          return ret;
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-1',
          type: 'time',
          ticks: {
            callback: function (value: string, index: any, values: any) {
              let [dateStr, dateType] = value.split(' ', 2);
              const dateFormat = (dateDisplayFormats as any)[dateType] || dateDisplayFormats.day;
              return moment.tz(dateStr, timeZone).format(dateFormat);
            },
          },
          time: {
            unit: timeUnit,
            displayFormats: {
              minute: 'YYYY-MM-DD[T]HH:mm:ssZZ [minute]',
              day: 'YYYY-MM-DD[T]HH:mm:ssZZ [day]'
            },
            stepSize: stepSize ? stepSize : (timeUnit === "minute" ? 60 : 1),
          }
        },
      ],
      yAxes: yAxisItems.map((item, i) => {
        if (!item.hide_axis_label) axis_label_count++
        return (
          {
            display: item.type !== "Alarm limit" && !item.hide_axis_label,
            scaleLabel: {
              display: true,
              labelString:
                individual_axes ? (item.symbol ? `${item.label} ${item.symbol}` : item.label) : ''
                  || axisTitle ? axisTitle : ""
            },
            gridLines: {
              display: true,
              color: "#f2f2f2"
            },
            ticks: {
              beginAtZero: false,
              fontSize: 16,
              min: min,
              max: max,
            },
            id: item.axis_id ? item.axis_id : `y-${i}`,
            position: item.axis_id ? (item.axis_id % 2 === 0 ? 'right' : 'left') : (axis_label_count % 2 === 0 ? 'right' : 'left'),
          }
        )
      }),
    },
    pan: {
      enabled: false,
      mode: 'xy'
    },
    zoom: {
      enabled: true,
      drag: true,
      mode: 'x',
      onZoomComplete: function () { setRedrawChart(false) }
    },
    hover: {
      mode: 'x'
    },
  };

  return (
    <div style={{ cursor: "zoom-in" }}>
      <Line data={chartData} options={chartOptions} redraw={redrawChart} />
    </div>
  )
};

export default LineChart;
