/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import LoginForm from "features/authentication/LoginForm";
import { useSelector } from 'react-redux';
import { RootState } from '../app/rootReducer';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/layout/LanguageSwitcher';

const UnauthenticatedFrontPage: React.FC = () => {
  const { t } = useTranslation();
  const initialLoginInProgress = useSelector(
    (state: RootState) => state.authentication.initialLoginInProgress
  );

  return (
    <div className="page-wrapper">
      <div className="page-inner bg-brand-gradient">
        <div className="page-content-wrapper bg-transparent m-0" style={{backgroundColor: 'white'}}>
          <div className="height-10 w-100 shadow-lg px-4" style={{backgroundColor: 'white'}}>
            <div className="d-flex align-items-center container p-0">
              <div className="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="#" className="page-logo-link press-scale-down d-flex align-items-center">
                  <img src={`${process.env.PUBLIC_URL}/img/mittalogo.svg`} alt="Mitta Cloud" aria-roledescription="logo" />
                </a>
              </div>
              {/*
              <a href="page_register.html" className="btn-link text-white ml-auto">
                Create Account
              </a>
              */}
              <div className="ml-auto">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
          <div className="flex-1" style={{background: `url(${process.env.PUBLIC_URL}/img/svg/ehp-line-pattern.svg) no-repeat center bottom fixed`, backgroundSize: 'cover'}}>
            <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              {initialLoginInProgress ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <div className="row">
                  <div className="col col-md-6 col-lg-7 hidden-sm-down">
                    <h2 className="fs-xxl fw-500 mt-4 text-white">
                      {t('Control monitoring stations and analyse the data easily')}
                    </h2>
                    <p className="h3 fw-400 mt-3 mb-5 text-white opacity-80">
                      {t('Mitta Cloud receives measurement data from wireless measurement stations automatically and safely. Your personal username and password will allow you to see your own measurement stations, the data they collect and analyse the measurement data both numerically and graphically with the reporting tools.')}
                    </p>
                    <p className="h3 fw-400 mt-3 mb-5 text-white opacity-80">
                      {t('Mitta Cloud has been created as a flexible and modern tool: it can also receive measurement data from other sources such as laboratory analysis results for example, and communicate information to other systems through the API interface.')}
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                    <LoginForm />
                  </div>
                </div>
              )}
              <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2020 © Mitta Cloud by <a href="https://www.mitta.fi" className="text-white opacity-40 fw-500" title="Mitta Oy" target="_blank" rel="noopener noreferrer">Mitta Oy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnauthenticatedFrontPage;
