import * as React from 'react';
import { useState } from 'react';
import { Dashboard, DashboardDataResponse, DashboardLayout } from 'api';
import Panel from 'components/layout/Panel';
import WidgetDisplay from './WidgetDisplay';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataDisplayType,
  setDataPeriod,
  setDataRangeEnd,
  setDataRangeStart,
  updateCurrentDashboardData,
} from './dashboardsSlice';
import DateTimeRangeForm from '../../components/forms/DateTimeRangeForm';
import browserTimeZone from '../../utils/browserTimeZone';

export interface DashboardDisplayProps {
  dashboard: Dashboard;
  data: DashboardDataResponse;
  isLoading: boolean;
}

const DashboardDisplay = (props: DashboardDisplayProps) => {
  const { t } = useTranslation();
  const {
    dashboard,
    data,
    isLoading,
  } = props;

  const [isHidden, setIsHidden] = useState(true)

  return (
    <>
      <div id={`dashboard-${dashboard.id}`}>
        <DashboardHeader dashboard={dashboard} data={data} isLoading={isLoading} />
        <div className="dashboard-widgets">
          {dashboard.widgets.map(widget => {
            if (widget?.data?.hidable) {
              return (
                <Panel id={`widget-${widget.id}`} key={widget.id}>
                  <div>
                    <h1 className="float-left">{t(widget.data.hidable_title)}</h1>
                    <button type="button"
                      className="btn btn-primary float-right mb-3"
                      data-toggle="button"
                      aria-pressed="false"
                      onClick={() => setIsHidden(!isHidden)}
                    >
                      {isHidden ? t('Show') : t('Hide')}
                    </button>
                  </div>
                  <div style={isHidden ? { display: 'none' } : {}}>
                    <WidgetDisplay widget={widget} data={data} number_of_decimals={dashboard.number_of_decimals} timeZone={dashboard.time_zone || browserTimeZone} />
                  </div>
                </Panel>
              )
            } else {
              return (
                <Panel id={`widget-${widget.id}`} key={widget.id}>
                  {widget?.title === 'Lämpötila valitulla syvyydellä' ? <DashboardHeader dashboard={dashboard} data={data} isLoading={isLoading} /> : ''}
                  <WidgetDisplay widget={widget} data={data} number_of_decimals={dashboard.number_of_decimals} timeZone={dashboard.time_zone || browserTimeZone} />
                </Panel>
              )
            }
          })}
        </div>
      </div>
      <DashboardLayoutStyle dashboard={dashboard} />
    </>
  )
}

const DashboardHeader = (props: DashboardDisplayProps) => {
  const { t } = useTranslation();
  const {
    dashboard,
    data,
    isLoading,
  } = props;

  const timeFormat = "DD.MM.YYYY HH:mm:ss ZZ"
  const updateInterval = 10000;

  return (
    <div className="dashboard-header">
      <Panel>
        {isLoading && (
          <div style={{ float: 'right', marginTop: '10px' }}>
            <Spinner animation="border" />
          </div>
        )}
        <div>
          <strong>{t('Page updated')}:</strong>{' '}
          <Moment format={timeFormat} interval={updateInterval} tz={dashboard.time_zone}>{data.meta.updatedOn}</Moment>{' '}
          (<Moment fromNow interval={updateInterval} tz={dashboard.time_zone}>{data.meta.updatedOn}</Moment>)
        </div>
        {!['inclinometer', 'temperature'].includes(String(dashboard.type)) ? (
          <div>
            <strong>{t('Closest to the alarm limit')}:</strong>{' '}
            {data.meta.closestToAlarm ?
              (`${data.meta.closestToAlarm.title} (${data.meta.closestToAlarm.value})`)
              :
              ""
            }
          </div>
        ) : ""}
        <DashboardDateTimeSelector hideTimeFrames={['inclinometer', 'temperature'].includes(String(dashboard.type))} />
      </Panel>
    </div>
  );
}

const DashboardDateTimeSelector = (props: { hideTimeFrames: boolean }) => {
  const { t } = useTranslation();
  const { hideTimeFrames } = props;

  const [
    dataDisplayType,
    dataPeriodMinutes,
    dataRangeStart,
    dataRangeEnd,
  ] = useSelector(
    (state: RootState) => [
      state.dashboards.dataDisplayType,
      state.dashboards.dataPeriodMinutes,
      state.dashboards.dataRangeStart,
      state.dashboards.dataRangeEnd,
    ]
  );

  const dispatch = useDispatch();
  const [rangeFormVisible, setRangeFormVisible] = useState(dataDisplayType === 'range');

  const handleDataPeriodChange = (event: React.SyntheticEvent, minutes: number) => {
    event.preventDefault();
    dispatch(setDataPeriod(minutes));
    dispatch(setDataDisplayType('period'));
    setRangeFormVisible(false);
    dispatch(updateCurrentDashboardData());
  }

  const onChangeDataRangeStart = (value: moment.Moment) => {
    dispatch(setDataRangeStart(value.format()));
    if (dataRangeEnd) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }
  const onChangeDataRangeEnd = (value: moment.Moment) => {
    dispatch(setDataRangeEnd(value.format()));
    if (dataRangeStart) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }

  const resetDataRange = () => {
    dispatch(setDataRangeStart(null));
    dispatch(setDataRangeEnd(null));
    dispatch(updateCurrentDashboardData());
  }

  const onClickCustomPeriod = (event: any) => {
    event.preventDefault();
    setRangeFormVisible(true);
    if (dataDisplayType !== 'range' && dataRangeStart && dataRangeEnd) {
      dispatch(setDataDisplayType('range'));
      dispatch(updateCurrentDashboardData());
    }
  }

  return (
    <>
      <div>
        <strong>{t('Data period')}:</strong>{' '}
        {!hideTimeFrames && (
          <>
            <Link
              to="#"
              onClick={(event) => handleDataPeriodChange(event, 45)}
              className="mr-5 ml-2"
              style={(dataDisplayType === 'period' && dataPeriodMinutes === 45) ? { fontWeight: 'bold' } : {}}
            >
              45 min
            </Link>
            <Link
              to="#"
              onClick={(event) => handleDataPeriodChange(event, 1440)}
              className="mr-5 ml-2"
              style={(dataDisplayType === 'period' && dataPeriodMinutes === 1440) ? { fontWeight: 'bold' } : {}}
            >
              24 h
            </Link>
          </>
        )}
        <Link
          to="#"
          onClick={onClickCustomPeriod}
          style={(dataDisplayType === 'range') ? { fontWeight: 'bold' } : {}}
          className="mr-5 ml-2"
        >
          {t('Select time period')}
        </Link>
      </div>
      {rangeFormVisible && (
        <>
          <DateTimeRangeForm
            currentStart={dataRangeStart}
            currentEnd={dataRangeEnd}
            onChangeStart={onChangeDataRangeStart}
            onChangeEnd={onChangeDataRangeEnd}
            resetDataRange={resetDataRange}
          />
        </>
      )}
    </>
  )
}

const DashboardLayoutStyle = (props: { dashboard: Dashboard }) => {
  const { dashboard } = props;

  if (dashboard.layouts.length === 0) {
    return <></>;
  }

  const namespace = `#dashboard-${dashboard.id} .dashboard-widgets`;

  const layoutStyle = (layout: DashboardLayout) => {
    const rows = layout.template.split('\n').map(s => s.trim()).filter(s => s);
    const numColumns = (rows[0] || '').split(/\s/).length;
    const hiddenWidgets = layout.hidden ? layout.hidden.trim().split(/\s/) : [];

    const styleBody = `
    ${namespace} {
      grid-template-columns: repeat(${numColumns}, 1fr);
      grid-template-areas:\n${rows.map(s => `"${s}"`).join('\n')};
    }
    ${
      dashboard.widgets.map(widget => `
        ${namespace} #widget-${widget.id} {
          display: ${hiddenWidgets.includes(widget.id) ? 'none' : 'block'};
        }
      `).join('\n')
      }`;
    if (layout.size === 'all') {
      return styleBody;
    }
    const minWidths = {
      'all': 0,
      'xxl': 1400,
      'xl': 1200,
      'lg': 992,
      'md': 768,
      'sm': 576,
      'xs': 0,
    }
    const minWidth = minWidths[layout.size];
    return `
    @media (min-width: ${minWidth}px) {
      ${styleBody}
    }
    `;
  }

  return (
    <style>{`
    ${namespace} {
      display: grid;
      grid-gap: 10px;
    }
    ${dashboard.widgets.map(widget => (
      `${namespace} #widget-${widget.id} {
          grid-area: ${widget.id};
        }`
    )).join('\n')}
    ${dashboard.layouts.map(layoutStyle).join('\n')}
    `}</style>
  )
}
export default DashboardDisplay;
